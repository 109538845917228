import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutLittleBigSnake extends Component{
    render(){
        let title = 'Little Big Snake',
        description = 'Игра Little Big Snake разработана командой Неодинамики и запущена в браузерном виде в начале 2018 года, с первых же месяцев набрав несколько миллионов игроков по всему миру без какой-либо рекламы, только благодаря людской молве. Спустя полгода издателем стала американская компания Addicting Games (в 2021 вошла в состав Enthusiast Gaming). Еще спустя год игра была портирована на Unity 3D и вышла на мобильных устройствах (iOS и Android). Неодинамика продолжает развивать свою игру, добавляя туда все новые и новые интересные фичи.',
        description2 = 'Игровой процесс Little Big Snake основан на популярной slither-механике с кучей собственных уникальных особенностей и улучшений, это безусловно одна из самых качественных и успешных игр в своем жанре на мировой арене. В игре большое количество красочных анимированных скинов змей, есть захватывающий групповой геймплей, погоня за Мятежниками, удивительные подземелья, прокачиваемые петы и многое другое. Глубина проработки баланса LBS соответствует кибер-спортивным играм.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_neodinamika_little_big_snake.jpg" alt="Little Big Snake Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2018</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>Web, Mobile (iOS/Android)</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Multiplayer Slither Action (IO Game)</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Аудитория:</div>
                                                <div>Более 50 миллионов</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Metacritic:</div>*/}
                                            {/*    <div>9999</div>*/}
                                            {/*</div>*/}
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Награды:</div>*/}
                                            {/*    <div>9999</div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>

                                        <div className="purchase-btn mb--30">
                                            <a href="https://littlebigsnake.com/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos">
                                            <div className="video">
                                                <iframe width="" height="100%"
                                                        src="https://www.youtube.com/embed/7rKVlAkRDKc"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/y4oOlnXjJA8"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/081vHhM1lVw"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <div className="purchase-btn mt--30 mb--30">
                                            <a href="https://www.youtube.com/c/SerkanAy/videos" className="btn-transparent" target="_blank" rel="noopener noreferrer">Еще видео</a>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutLittleBigSnake;