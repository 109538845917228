import React, { Component } from "react";
import PortfolioGalleryPopupMusaic from "../../../elements/portfolio/PortfolioGalleryPopupMusaic";
import {FiChevronsRight} from "react-icons/all";

class AboutMusaic extends Component{
    render(){
        let title = 'Музаик',
        description = 'Musaic Box – оригинальная и очень своеобразная игра, в основу которой положена необычная идея: собирать музыкальные композиции из отдельных фрагментов. Чтобы восстановить мелодии, нужно отыскать ноты, спрятанные в доме коллекционера старинных музыкальных инструментов.',
        description2 = 'Игра «Музаик» вышла в финал фестиваля независимых игр IGF Award 2009 сразу в двух номинациях: Excellence in Audio и Excellence in Design, в последней из которых заняла первое место. На Седьмой международной конференции разработчиков игр КРИ в Москве игра получила награду как «Лучшая Casual-игра». Игра была полностью разработана в стенах KranX Productions.\n';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_musaic.jpg" alt="Musaic Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2008</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC, Mobile, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Musical Puzzle</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Награды:</div>
                                                <div>
                                                    <div>КРИ Awards 2009: "Лучшая Casual-игра"</div>
                                                    <div>GDC Independent Games Festival Award 2009: Excellence in Audio и Excellence in Design
                                                    </div>

                                                    <img width="" src="/assets/images/icons/rewards_musaic_box.jpg" alt="cup"/>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/29130/Musaic_Box/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Игра в STEAM <FiChevronsRight/></a>
                                        </div>
                                        <PortfolioGalleryPopupMusaic/>
                                        <div className="mt--50">
                                            <h3 className="mb--30">Отзывы</h3>
                                            <div className="quotes">
                                                <figure className="quote">
                                                    <blockquote>
                                                        "I highly recommend you give Musaic Box a try. Both the puzzle and music game genres are filled with copycats and me-too games, but Musaic Box marries those two categories and creates something unique."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>IGN</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Music and spacial puzzling merge in a light symphony of creative gaming."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>148Apps</cite></figcaption>
                                                </figure>
                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutMusaic;