import React, { Component } from "react";

class AboutKranx extends Component{
    render(){
        let title = 'KranX Productions',
        description = '',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx.jpg" alt="About Kranx"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            Компания <b>KranX Productions</b> была основана в 2004 году совместно с фирмой “1С” руководителем, ведущим гейм-дизайнером и программистом калининградской студии <i>«K-D
                                            LAB»</i> Андреем Кузьминым. Именно он руководил разработкой культовых <i>«Вангеров»</i> и <i>«Периметра»</i>, а также создал представленную там уникальную игровую вселенную (<i>Психосферу</i>). KranX стала первой российской продюсерской компанией и сначала была сосредоточена на внешнем управлении множеством проектов, помогая другим командам доводить их до релиза. Параллельно KranX прототипировала внутри много оригинальных игровых концепций, исследуя разные платформы и бизнес-модели. Было выпущено несколько хитов в казуальном жанре (Yumsters, Musaic Box), а затем долгое время компания была сосредоточена на развитии кросс-платформенной онлайновой стратегии “King’s Bounty: Legions” в кооперации с Katauri Interactive, совместно с которой затем была образована компания <b>Неодинамика</b>.
                                        </p>
                                        {/*<p className="description">{description}</p>*/}
                                        {/*<p className="description">{description2}</p>*/}
                                        {/*<div className="purchase-btn">*/}
                                        {/*    <a href="https://kranx.com/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт</a>*/}
                                        {/*</div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKranx;