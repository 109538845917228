import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutKatauri extends Component{
    render(){
        let title = 'Katauri Interactive',
        description = '',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri.jpg" alt="About Katauri"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            Компания <b>Katauri Interactive</b> - знаменитый разработчик компьютерных игр. Основана во Владивостоке в 2004 году <a className="" href="https://ru.wikipedia.org/wiki/%D0%93%D1%83%D1%81%D0%B0%D1%80%D0%BE%D0%B2,_%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87" target="_blank" rel="noopener noreferrer">Дмитрием
                                            Гусаровым</a> и Александром Языниным на базе компаний Newgame Software (2002) и Elemental Games (1999). Наш талантливый коллектив создал знаменитые серии игр: Space Rangers и King's Bounty, получившие самые высокие оценки во всем мире и вошедшие в топы PC игр разных лет. В 2012 году Katauri Interactive объединились с KranX Productions в новую компанию <b>Neodinamika</b>.
                                        </p>
                                        <p className="description">
                                            Связаться с нами:  <a href="mailto:mail@katauri.com">mail@katauri.com</a>
                                        </p>
                                        <div className="purchase-btn mb--30 mt--30">
                                            <a href="https://ru.wikipedia.org/wiki/Katauri_Interactive"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKatauri;