import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import AboutComp from "../component/HomeLayout/homeOne/About";
import FooterThree from "../component/footer/FooterThree";
import {Link} from "react-router-dom";

const Portfolio_image = <img src="/assets/images/portfolio/portfolio_neodinamika_little_big_snake.jpg"
                             alt="обложка игры Little Big Snake"/>;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio_neodinamika_musicwars.jpg"
                              alt="обложка игры Music Wars"/>;
const list = [
    {
        image: Portfolio_image,
        category: 'Online, Casual',
        title: 'Little Big Snake',
        link: '/little-big-snake',
        // description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.'
    },
    {
        image: Portfolio_image2,
        category: 'Online, Casual',
        title: 'Music Wars',
        link: '/music-wars',
        // description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    }
]
class About extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='О компании'/>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'О компании'}/>
                {/* End Breadcrump Area */}

                {/* Start About Area */}
                <div className="about-area ptb--80 bg_color--1">
                    <AboutComp/>
                </div>
                {/* End About Area */}


                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    {/*<span className="subtitle">Проекты</span>*/}
                                    <h2 className="title">Наши игры</h2>
                                    <p className="description">Мы создаём игры более 20 лет, многие из них стали хитами и легендами. Наша многомиллионная аудитория постоянно растет на всех континентах!</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* Start Single Team  */}
                            <div className="">
                                <div style={{display:'flex', justifyContent: 'center',gap: '20px'}}>
                                    {list.map((value, index) => (
                                        <div className="single_im_portfolio" key={index}>
                                            <div className="im_portfolio">
                                                <div className="thumbnail_inner">
                                                    <div className="thumbnail">
                                                        <Link to={value.link}>
                                                            {value.image}
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <div className="portfolio_heading">
                                                            <div className="category_list">
                                                                <Link to={value.link}>{value.category}</Link>
                                                            </div>
                                                            <h4 className="title"><Link
                                                                to={value.link}>{value.title}</Link></h4>
                                                        </div>
                                                        <div className="portfolio_hover">
                                                            <p>{value.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link className="transparent_link" to={value.link}></Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--80 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    {/*<span className="subtitle">Неодинамика в цифрах</span>*/}
                                    <h2 className="title">
                                        <span style={{color: '#e51319'}}>Нео</span>динамика в цифрах
                                    </h2>
                                    <p className="description">Растем от проекта к проекту</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne/>
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/*<Footer />*/}
                <FooterThree/>

            </React.Fragment>
        )
    }
}

export default About