import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import AboutCompMusaic from "../component/HomeLayout/homeOne/AboutMusaic";
import FooterThree from "../component/footer/FooterThree";
import {Link} from "react-router-dom";
import BreadcrumbKranx from "./common/BreadcrumbKranx";


const Portfolio_image = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_kings_bounty_legions.jpg" alt="обложка игры King’s Bounty: Legions"/>;

const Portfolio_image2 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_notime_for_dragons.jpg" alt="обложка игры Не время для драконов"/>;

//const Portfolio_image3 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_truth_about_nineth.jpg" alt="обложка игры Правда о девятой роте"/>;

const Portfolio_image4 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_yamsters.jpg" alt="обложка игры Серия «Нямстеры»"/>;

// const Portfolio_image5 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_musaic.jpg" alt="обложка игры Музаик"/>;

// const Portfolio_image6 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_bashenki.jpg" alt="обложка игры Башенки"/>;

//const Portfolio_image7 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_tasty_way.jpg" alt="обложка игры Tasty Way"/>;

//const Portfolio_image8 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_cubium.jpg" alt="обложка игры Cubium"/>;

const Portfolio_image9 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_hammerfight.jpg" alt="обложка игры Hammerfight"/>;

const Portfolio_image10 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_vangers.jpg" alt="обложка игры Вангеры"/>;

const Portfolio_image11 = <img className="w-100" src="/assets/images/portfolio-kranx/portfolio_kranx_perimeter.jpg" alt="обложка игры Периметр"/>

const list = [
    {
        image: Portfolio_image,
        category: 'Online',
        title: 'King’s Bounty: Legions',
        link: '/kings-bounty-legions',
    },
    {
        image: Portfolio_image10,
        category: 'PC',
        title: 'Вангеры',
        link: '/vangers',
    },
    {
        image: Portfolio_image11,
        category: 'PC',
        title: 'Периметр',
        link: '/perimeter',
    },
    {
        image: Portfolio_image4,
        category: 'Casual, iPhone',
        title: 'Серия «Нямстеры»',
        link: '/yamsters',
    },
    // {
    //     image: Portfolio_image5,
    //     category: 'Casual',
    //     title: 'Музаик',
    //     link: '/musaic',
    // },
    {
        image: Portfolio_image2,
        category: 'PC',
        title: 'Не время для драконов',
        link: '/no-time-for-dragons',
    },
    {
        image: Portfolio_image9,
        category: 'Indie',
        title: 'Hammerfight',
        link: '/hammerfight',
    },
    // {
    //     image: Portfolio_image3,
    //     category: 'PC',
    //     title: 'Правда о девятой роте',
    //     link: '/truth-about-ninth',
    // },
    // {
    //     image: Portfolio_image6,
    //     category: 'Casual',
    //     title: 'Башенки',
    //     link: '/bashenki',
    // },
    // {
    //     image: Portfolio_image7,
    //     category: 'iPhone',
    //     title: 'Tasty Way',
    //     link: '/tasty-way',
    // },
    // {
    //     image: Portfolio_image8,
    //     category: 'Indie',
    //     title: 'Cubium',
    //     link: '/cubium',
    // },
]


class Musaic extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Музаик' />{/*было About*/}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <BreadcrumbKranx title={'Музаик'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area */}
                <div className="about-area ptb--80 bg_color--1">
                    <AboutCompMusaic />
                </div>
                {/* End About Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 pt--40 pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    {/*<span className="subtitle">Проекты</span>*/}
                                    <h2 className="title">Другие наши игры</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="main_portfolio_wrapper">
                            <div className="main_portfolio" >
                                {list.map((value, index) => (
                                    <div className="single_im_portfolio" key={index}>
                                        <div className="im_portfolio">
                                            <div className="thumbnail_inner">
                                                <div className="thumbnail">
                                                    <Link to={value.link}>
                                                        {value.image}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <div className="portfolio_heading">
                                                        <div className="category_list">
                                                            <Link to={value.link}>{value.category}</Link>
                                                        </div>
                                                        <h4 className="title"><Link
                                                            to={value.link}>{value.title}</Link></h4>
                                                    </div>
                                                    <div className="portfolio_hover">
                                                        <p>{value.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to={value.link}></Link>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/*<Footer />*/}
                <FooterThree />

            </React.Fragment>
        )
    }
}
export default Musaic