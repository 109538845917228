import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import AboutCompLittleBigSnake from "../component/HomeLayout/homeOne/AboutLittleBigSnake";
import FooterThree from "../component/footer/FooterThree";
import BreadcrumbNeodinamika from "./common/BreadcrumbNeodinamika";

class LittleBigSnake extends Component{
    render(){
        return(
            <React.Fragment>
                {/*было About*/}
                <PageHelmet pageTitle='Little Big Snake' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <BreadcrumbNeodinamika title={'Little Big Snake'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area */}
                <div className="about-area ptb--80 bg_color--1">
                    <AboutCompLittleBigSnake />
                </div>
                {/* End About Area */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--80 bg_color--1 bg_image bg_image--18" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <h4 className="theme-gradient">Скачать игру</h4>
                                            <p>Милые и опасные змеи населяют этот мир. Сражайтесь вместе с друзьями против своих врагов. После смерти можно переродиться в прекрасную жужу. Ты сможешь летать! Баблы и слизни - отличная еда. Окружай и ешь!</p>
                                            <div className="flex-space-between">
                                                <a href="https://play.google.com/store/apps/details?id=air.com.littlebigsnake.littlebigsnake" target="_blank" rel="noopener noreferrer">
                                                    <img src="/assets/images/logo/googleplay-logo.png" alt="google play icon"/>
                                                </a>
                                                <a href="https://apps.apple.com/us/app/little-big-snake/id1441412472" target="_blank" rel="noopener noreferrer">
                                                    <img src="/assets/images/logo/appstore-logo.png" alt="google play icon"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/*<Footer />*/}
                <FooterThree />

            </React.Fragment>
        )
    }
}
export default LittleBigSnake
