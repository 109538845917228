import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import AboutCompGeneral from "../component/HomeLayout/homeOne/AboutGeneral";
import FooterThree from "../component/footer/FooterThree";
import {Link} from "react-router-dom";
import BreadcrumbKatauri from "./common/BreadcrumbKatauri";


const Portfolio_image = <img className="w-100" src="/assets/images/portfolio-katauri/portfolio_katauri_royal_quest.jpg"
                             alt="обложка игры Royal Quest"/>;

const Portfolio_image2 = <img className="w-100"
                              src="/assets/images/portfolio-katauri/portfolio_katauri_kings_bounty_legend.jpg"
                              alt="обложка игры King's Bounty: Легенда о рыцаре"/>;

const Portfolio_image3 = <img className="w-100"
                              src="/assets/images/portfolio-katauri/portfolio_katauri_kings_bounty_princess.jpg"
                              alt="обложка игры King's Bounty: Принцесса в доспехах"/>;

const Portfolio_image4 = <img className="w-100"
                              src="/assets/images/portfolio-katauri/portfolio_katauri_kings_bounty_crossworlds.jpg"
                              alt="обложка игры King's Bounty: Перекрестки миров"/>;

const Portfolio_image5 = <img className="w-100"
                              src="/assets/images/portfolio-katauri/portfolio_katauri_space_rangers_dominators.jpg"
                              alt="обложка игры Космические рейнджеры 2: Доминаторы"/>;

const Portfolio_image6 = <img className="w-100"
                              src="/assets/images/portfolio-katauri/portfolio_katauri_space_rangers_reboot.jpg"
                              alt="обложка игры Космические рейнджеры 2: Доминаторы. Перезагрузка"/>;

const Portfolio_image7 = <img className="w-100"
                              src="/assets/images/portfolio-katauri/portfolio_katauri_space_rangers.jpg"
                              alt="обложка игры Космические рейнджеры"/>;

// const Portfolio_image8 = <img className="w-100" src="/assets/images/portfolio-katauri/portfolio_katauri_general.jpg"
//                               alt="обложка игры Генерал"/>;


const list = [
    {
        image: Portfolio_image,
        category: 'Online, РС',
        title: 'Royal Quest',
        link: '/royal-quest',
    },
    {
        image: Portfolio_image2,
        category: 'PC',
        title: 'King\'s Bounty: Легенда о рыцаре',
        link: '/kings-bounty-legend',
    },
    {
        image: Portfolio_image3,
        category: 'PC',
        title: 'King\'s Bounty: Принцесса в доспехах',
        link: '/kings-bounty-princess',
    },
    {
        image: Portfolio_image4,
        category: 'PC',
        title: 'King\'s Bounty: Перекрестки миров',
        link: '/kings-bounty-crossworlds',
    },
    {
        image: Portfolio_image5,
        category: 'PC',
        title: 'Космические рейнджеры 2: Доминаторы',
        link: '/space-rangers-dominators',
    },
    {
        image: Portfolio_image6,
        category: 'PC',
        title: 'Космические рейнджеры 2: Доминаторы. Перезагрузка',
        link: '/space-rangers-reboot',
    },
    {
        image: Portfolio_image7,
        category: 'PC',
        title: 'Космические рейнджеры',
        link: '/space-rangers',
    },
    // {
    //     image: Portfolio_image8,
    //     category: 'PC',
    //     title: 'Генерал',
    //     link: '/general',
    // },
]


class General extends Component {
    render() {
        return (
            <React.Fragment>
                {/*было About*/}
                <PageHelmet pageTitle='Генерал'/>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>
                {/* Start Breadcrump Area */}
                <BreadcrumbKatauri title={'Генерал'}/>
                {/* End Breadcrump Area */}

                {/* Start About Area */}
                <div className="about-area ptb--80 bg_color--1">
                    <AboutCompGeneral/>
                </div>
                {/* End About Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 pt--40 pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    {/*<span className="subtitle">Проекты</span>*/}
                                    <h2 className="title">Другие наши игры</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="main_portfolio_wrapper">
                            <div className="main_portfolio" >
                                {list.map((value, index) => (
                                    <div className="single_im_portfolio" key={index}>
                                        <div className="im_portfolio">
                                            <div className="thumbnail_inner">
                                                <div className="thumbnail">
                                                    <Link to={value.link}>
                                                        {value.image}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <div className="portfolio_heading">
                                                        <div className="category_list">
                                                            <Link to={value.link}>{value.category}</Link>
                                                        </div>
                                                        <h4 className="title"><Link
                                                            to={value.link}>{value.title}</Link></h4>
                                                    </div>
                                                    <div className="portfolio_hover">
                                                        <p>{value.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to={value.link}></Link>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/*<Footer />*/}
                <FooterThree/>

            </React.Fragment>
        )
    }
}

export default General
