import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import FooterThree from "../component/footer/FooterThree";
import {FaLinkedinIn, FaTelegramPlane} from "react-icons/fa";
import {MdEmail} from "react-icons/all";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;


class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Контакты'/>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area rn-page-title-area ptb--80 bg_image-contacts bg_image--18 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Свяжитесь с нами</h2>
                                    <p>Мы открыты для предложений о сотрудничестве</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title ">
                                    {/*<span className="subtitle">Свяжитесь с нами</span>*/}
                                    <h2 className="title text-center">Контакты</h2>
                                    {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                    <div className='containerGrid'>

                                        <div className="rn-address emails">
                                            <div className="icon">
                                                <MdEmail/>
                                            </div>

                                            <div className="email-wrap">
                                                <div className="inner inner-email">
                                                    <h4 className="title">Общие вопросы</h4>
                                                    <p><a
                                                        href="mailto:contact@neodinamika.com">contact@neodinamika.com</a>
                                                    </p>
                                                </div>
                                                <div className="inner inner-email">
                                                    <h4 className="title">Развитие бизнеса<br/> и партнерство</h4>
                                                    <p><a
                                                        href="mailto:bizdev@neodinamika.com">bizdev@neodinamika.com</a>
                                                    </p>
                                                </div>
                                                <div className="inner inner-email">
                                                    <h4 className="title">Пресса</h4>
                                                    <p><a href="mailto:press@neodinamika.com">press@neodinamika.com</a>
                                                    </p>
                                                </div>
                                                <div className="inner inner-email">
                                                    <h4 className="title">Вакансии</h4>
                                                    <p><a href="mailto:job@neodinamika.com">job@neodinamika.com</a></p>
                                                </div>
                                                <div className="inner inner-email">
                                                    <h4 className="title">Игры <br/>Katauri Interactive</h4>
                                                    <p><a href="mailto:mail@katauri.com">mail@katauri.com</a></p>
                                                </div>
                                                <div className="inner inner-email">
                                                    <h4 className="title">Игры <br/>KranX Productions</h4>
                                                    <p><a href="mailto:games@kranx.com">games@kranx.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rn-address linkedin">
                                            <div className="icon">
                                                <FaLinkedinIn/>
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">LinkedIn</h4>
                                                <p>
                                                    <a href="https://www.linkedin.com/company/72502603"
                                                       className="" target="_blank" rel="noopener noreferrer">
                                                        Наша группа</a>
                                                </p>
                                            </div>
                                        </div>
                                        {/*<div className="rn-address telegram">*/}
                                        {/*    <div>*/}
                                        {/*        <div className="icon">*/}
                                        {/*            <FaTelegramPlane/>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="inner">*/}
                                        {/*            <h4 className="title">Telegram</h4>*/}
                                        {/*            <p>@anastaseeya39</p>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="qr">*/}
                                        {/*        <img width={140} src="/assets/images/contact/qr-telegram.png"*/}
                                        {/*             alt="qr code"/>*/}
                                        {/*    </div>*/}

                                        {/*</div>*/}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <FooterThree/>
                {/*<Footer />*/}
            </React.Fragment>
        )
    }
}

export default Contact