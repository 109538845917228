import React, { Component } from "react";
import PortfolioGalleryPopupVangers from "../../../elements/portfolio/PortfolioGalleryPopupVangers";
import {FiChevronsRight} from "react-icons/all";

class AboutVangers extends Component{
    render(){
        let title = 'Вангеры',
        description = '«Ва́нгеры» (англ. Vangers: One For The Road) — культовая игра, выпущенная калининградской компанией К-Д ЛАБ в 1998 году под руководством и непосредственным участием Андрея Кузьмина как автора оригинальной вселенной и музыкальных тем, а также как основного разработчика технологии Surmap и игровой логики. Игра была издана силами компаний Бука (Россия) и Interactive Magic (остальной мир). В 2014 году права перешли к KranX Productions и вышло переиздание игры в Steam/GOG, включающее обновлённую версию игры для Linux, macOS и Windows, а в 2020-2022 годах игра была портирована на мобильные устройства. В настоящее время ведётся разработка версии с открытым исходным кодом силами влюблённого в проект Vangers Community. Спустя 20+ лет после релиза Вангеры получили вторую жизнь благодаря обзорам популярных видеоблогеров, набравших миллионы просмотров и новых поклонников этого уникального игрового артефакта.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_vangers.jpg" alt="Vangers Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>1998, 2014</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC, Mobile, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Action Adventure Crazy Mix</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Награды:</div>
                                                <div>
                                                    <div>Absolute Games: оценка "Шедевр"</div>
                                                    <div>Steam Reviews: Overwhelmingly Positive</div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="description">{description}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://vangers.ru/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/264080/Vangers/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Игра в STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%92%D0%B0%D0%BD%D0%B3%D0%B5%D1%80%D1%8B"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos">
                                            <div className="video">
                                                <iframe width="" height="100%"
                                                        src="https://www.youtube.com/embed/7smUD83nb_8"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/Q85UNox8i0o"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/P9R7QJ5Sh1o"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupVangers/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutVangers;