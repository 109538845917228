import React, {Component, Fragment} from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {FiHeart, FiClock, FiCheckCircle, FiAward, FiUser, FiUsers} from "react-icons/fi";


class CounterOne extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }

    render() {
        let Data = [

            {
                icon: <FiClock/>,
                countNum: 20,
                countTitle: 'лет',
                countTitle2: 'на рынке',
            },

            {
                icon: <FiUsers/>,
                countNum: 87,
                countTitle: 'млн',
                countTitle2: 'игроков',
            },

            {
                icon: <FiCheckCircle/>,
                countNum: 17,
                countTitle: 'игр',
                countTitle2: 'создано',
            },

            {
                icon: <FiHeart/>,
                countNum: 300,
                countTitle: '%',
                countTitle2: 'аддиктивность',
            },
        ];

        return (
            <Fragment>
                <div className="row mt--30 counter_flex">
                    {Data.map((value, index) => (
                        <div className="im_single_counterup im_single_counterup_width" key={index}>
                            <div className="im_counterup">
                                <div className="inner">
                                    <div className="icon">
                                        {value.icon}
                                    </div>
                                    <h2 className="counter">
                                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top: 10}}
                                                          delayedCall>
                                            <CountUp end={this.state.didViewCountUp ? value.countNum : 0}/>
                                        </VisibilitySensor>
                                    </h2>
                                    <div>
                                        <p className="description">{value.countTitle}</p>
                                        <p className="description">{value.countTitle2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}

export default CounterOne;