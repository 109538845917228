import React, { Component } from "react";
import {Link} from "react-router-dom";

class About extends Component{
    render(){
        let title = 'Неодинамика',
        description = '',
        description2 = '';


        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_neodinamika.jpg" alt="About Neodinamika"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">Успешная интернациональная студия, образованная в 2011 году объединением компаний <Link  to="/about-katauri">Katauri Interactive</Link> и <Link to="/about-kranx">KranX Productions</Link>, которые уже имели на тот момент за плечами многолетний опыт создания блестящих популярных игр для разных платформ.</p>
                                        <p className="description">Основным направлением Неодинамики стало создание собственных высококачественных кросс-платформенных онлайн игр с очень хорошо проработанным и сбалансированным игровым дизайном и интегрированной в него монетизацией.</p>
                                        {/*<div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">PURCHASE IMROZ</Link>
                                        </div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;