import React, { Component } from "react";
import PortfolioGalleryPopupYamsters from "../../../elements/portfolio/PortfolioGalleryPopupYamsters";
import {FiChevronsRight} from "react-icons/all";

class AboutYamsters extends Component{
    render(){
        let title = 'Нямстеры',
        description = '«Нямстеры!» (Yumsters!) — серия оригинальных казуальных игр от KranX. Игры основаны на инновационном игровом принципе и предназначены для широкой аудитории. Игры были изданы компаниями 1C, Big Fish Games, G5 Entertainment, NevoSoft и HeroCraft для разных платформ.',
        description2 = 'Нямстеры — забавные существа, живущие в норках. Задача игрока – управлять Нямстерами так, чтобы они все время ели как можно эффективнее (задача на параллельный оптимальный менеджмент множества геометрических агентов). Для этого существ нужно вытягивать из своих норок и с умом прикреплять к фруктам. Нужно избегать насекомых-вредителей, использовать бонусы и не давать никому из Нямстеров заскучать.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_yamsters.jpg" alt="Yamsters Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2007-2009</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC, Mobile, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Casual Puzzle Arcade</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Награды:</div>
                                                <div>
                                                    <div>КРИ Awards 2007: "Лучший игровой дизайн"</div>
                                                    <img width="" src="/assets/images/icons/rewards_yumsters.jpg" alt="cup"/>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                       {/* <div className="purchase-btn">
                                            <a href="https://royalquest.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>

                                        </div>*/}
                                        <PortfolioGalleryPopupYamsters/>
                                        <div className="mt--50">
                                            <h3 className="mb--30">Отзывы</h3>
                                            <div className="quotes">
                                                <figure className="quote">
                                                    <blockquote>
                                                        "It’s a winner! I wouldn’t give a second thought, it’s a solid puzzle with time management. Two genres mixed into one!"
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>TheAppera.com</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Grab it!"
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>TouchMyApps.com</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "This is one of those rare puzzle games that's super easy to pick but virtually impossible to put down."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>GameZebo.com about PC version - The winner of GameZebo's Best of 2008!</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Surprisingly fun gameplay for what initially appears as yet another matching puzzle game. The game makes great use of the touch controls, and the learning curve is just right"
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>www.TouchGen.com</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Cute, colorful, addicting, and simple."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>AppAdvice.com</cite></figcaption>
                                                </figure>
                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutYamsters;