import React, {Component, Fragment} from "react";
import {Link} from 'react-router-dom';
import Header from "../component/header/Header";
import CounterOne from "../elements/counters/CounterOne";
import FooterThree from "../component/footer/FooterThree";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Helmet from "../component/common/Helmet";

// const image1 =
//   "/assets/images/bg/paralax/bg-image-3-overlay.jpg";

const Portfolio_image = <img src="/assets/images/portfolio/portfolio_neodinamika_little_big_snake.jpg"
                             alt="обложка игры Little Big Snake"/>;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio_neodinamika_musicwars.jpg"
                              alt="обложка игры Music Wars"/>;


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Neodinamika - онлайн игры от создателей Космических рейнджеров, King\'s Bounty и Вангеров!',
        description: 'Опыт Katauri Interactive и KranX Productions в новом воплощении',
        buttonText: 'Service',
        buttonText2: 'See Projects',
        buttonLink: '/contact',
        buttonLink2: '/portfolio'
    }
]

const list = [
    {
        image: Portfolio_image,
        category: 'Online, Casual',
        title: 'Little Big Snake',
        link: '/little-big-snake',
        // description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.'
    },
    {
        image: Portfolio_image2,
        category: 'Online, Casual',
        title: 'Music Wars',
        link: '/music-wars',
        // description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
]

class CreativeAgency extends Component {
    render() {
        // const PostList = BlogContent.slice(0, 5);
        return (
            <Fragment>
                <Helmet pageTitle="Главная"/>
                <Header logo="light"/>
                {/* Start Slider Area   */}
                <div className="slider-activation" id="home">
                    <div
                        className="slider-paralax breadcrumb-area bg_image bg_image--18 d-flex align-items-center justify-content-center"
                        data-black-overlay="6"
                    >
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-4" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {/*{value.title ? <h1 className="title" style={{fontSize: '36px'}} dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}*/}
                                                <h1 className="title" >
                                                    <span>Neo</span><span style={{color: '#fff'}}>dinamika</span>
                                                </h1>
                                                <h2 style={{marginBottom: '0'}}>
                                                    Онлайн игры от создателей <Link to={'/space-rangers'}>
                                                    <span>Космических рейнджеров</span></Link>,
                                                </h2>
                                                <h2><Link to={'/kings-bounty-princess'}><span>King's
                                                    Bounty</span></Link> и <Link to={'/vangers'}>
                                                    <span>Вангеров!</span></Link>
                                                </h2>

                                                <p className="description">
                                                    Опыт <Link to={'/about-katauri'}>
                                                    <b><span>Katauri Interactive</span></b></Link> и <Link to={'/about-kranx'}>
                                                    <b><span>KranX Productions</span></b></Link> в новом воплощении
                                                </p>
                                                {/* <div className="slide-btn button-group">
                                                    <a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a>
                                                    <a className="btn-default btn-border btn-opacity" href={`${value.buttonLink2}`}>{value.buttonText2}</a>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    {/*<span className="subtitle">Проекты</span>*/}
                                    <h2 className="title">Наши игры</h2>
                                    <p className="description">Мы создаём игры более 20 лет, многие из них стали хитами и легендами. Наша многомиллионная аудитория постоянно растет на всех континентах!</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* Start Single Team  */}
                            <div className="">
                                <div style={{display:'flex', justifyContent: 'center',gap: '20px'}}>
                                    {list.map((value, index) => (
                                        <div className="single_im_portfolio" key={index}>
                                            <div className="im_portfolio">
                                                <div className="thumbnail_inner">
                                                    <div className="thumbnail">
                                                        <Link to={value.link}>
                                                            {value.image}
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <div className="portfolio_heading">
                                                            <div className="category_list">
                                                                <Link to={value.link}>{value.category}</Link>
                                                            </div>
                                                            <h4 className="title"><Link
                                                                to={value.link}>{value.title}</Link></h4>
                                                        </div>
                                                        <div className="portfolio_hover">
                                                            <p>{value.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link className="transparent_link" to={value.link}></Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    {/*<span className="subtitle">Неодинамика в цифрах</span>*/}
                                    {/*<h2 className="title">Растем от проекта к проекту</h2>*/}
                                    {/*<p className="description">Наша команда профессионалов показывает отличные*/}
                                    {/*    результаты</p>*/}
                                    <h2 className="title">
                                        <span style={{color: '#e51319'}}>Нео</span>динамика в цифрах
                                    </h2>
                                    <p className="description">Мы создаем игры, в которые играют десятилетиями!</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne/>
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Footer Style  */}
                <FooterThree/>
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeAgency;