import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutMusicWars extends Component{
    render(){
        let title = 'Music Wars',
        description = 'Music Wars - браузерная ММОРПГ, разработанная компанией Неодинамика и изданная компанией Destiny Development. Игра была запущена осенью 2012 года и уже более десяти лет развивается в стенах Неодинамики, обрастая все новыми механиками и контентом. Music Wars погружает игрока в жизнь Саунд Сити, где ему предстоит в жесткой PvP борьбе отстаивать свои музыкальные вкусы. Яркая анимированная графика, нестандартная боевая система привлекают не только отъявленых меломанов, но и всех поклонников компьютерных игр. Многомиллионная армия фанов этой онлайновой игры продолжает драться за свои территории, как бизнес-проект игра сверхприбыльна.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_neodinamika_musicwars.jpg" alt="Music Wars"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2012</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>Web</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Free2Play MMORPG</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Аудитория:</div>
                                                <div>Более 10 миллионов игроков</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Metacritic:</div>*/}
                                            {/*    <div>9999</div>*/}
                                            {/*</div>*/}
                                            <div className='item'>
                                                <div className='item-title'>Награды:</div>
                                                <div><img width="40px" src="/assets/images/icons/kri_logo.jpg" alt="cup"/>
                                                    <span>КРИ Awards 2013: </span>
                                                    <div>"Лучшая браузерная онлайн игра"</div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn">
                                            <a href="https://musicwars.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutMusicWars;