import React, { Component } from "react";
import PortfolioGalleryPopupRoyalQuest
    from "../../../elements/portfolio/PortfolioGalleryPopupRoyalQuest";
import {FiChevronsRight} from "react-icons/all";

class AboutRoyalQuest extends Component{
    render(){
        let title = 'Royal Quest',
        description = 'События MMORPG Royal Quest разворачиваются в уникальном мире Аура, где бок о бок уживаются магия, технология и алхимия. Ауре грозит опасность в виде черных алхимиков, стремящихся заполучить крайне редкий и ценный минерал элениум, обладающий уникальными свойствами. Для организации отпора врагам, король разыскивает отважных героев, способных навсегда изгнать захватчиков. В качестве награды героям он предлагает титулы, замки и прилегающие имения! Всем желающим стать лордами и получить во владение собственный замок, придется пройти немало суровых испытаний. На пути игроков встанут обитатели темных лесов и бескрайних пустынь, подземные жители и морские чудовища, болотные твари, геометры, орки, демоны и многочисленные орды нежити.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_royal_quest.jpg" alt="Royal Quest Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2011</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Издатель:</div>
                                                <div>Fulqrum Publishing Ltd.</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Massive Multiplayer Online Role Playing Game</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Рейтинги:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-metacritic.png"*/}
                                            {/*             alt="metacritic logo"/>*/}
                                            {/*        <span>Metascore 78 / 100</span>*/}
                                            {/*        <div>User Score 8.8 / 10</div>*/}
                                            {/*        <div>GameRankings 76%</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Награды:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>*/}
                                            {/*        <span>#54 Best PC Game of 2010</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://royalquest.com/ru/games/Royal_Quest"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/uEO4qB2Zztc"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupRoyalQuest/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutRoyalQuest;