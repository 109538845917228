import React, { Component } from "react";
import PortfolioGalleryPopupSpaceRangersReboot from "../../../elements/portfolio/PortfolioGalleryPopupSpaceRangersReboot";
import {FiChevronsRight} from "react-icons/all";

class AboutSpaceRangersReboot extends Component{
    render(){
        let title = 'Космические рейнджеры 2: Доминаторы. Перезагрузка',
        description = '',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_space_rangers_reboot.jpg" alt="Space Ranger Reboot Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2009</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Издатель:</div>
                                                <div>1C Entertainment</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Role Playing Game, Space trading and combat simulator</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Рейтинги:</div>
                                                <div>
                                                    <div>PC Gamer UK 88 / 100</div>
                                                    <img width="20px" src="/assets/images/icons/ico-metacritic.png"
                                                         alt="metacritic logo"/>
                                                    <span>User Score 8.8 / 10</span>
                                                </div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Награды:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>*/}
                                            {/*        <span>#46 Best PC Game of 2008</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*<p className="description">{description}</p>*/}
                                        {/*<p className="description">{description2}</p>*/}
                                        <p className="description">
                                            Переиздание космической саги содержит в себе первую и дополненную вторую части "Космических рейнджеров" и добавляет новые режимы игры, экран корабля, типы корпусов и новое оборудование с дополнительными характеристиками, а также текстовые квесты и карты планетарных боёв.
                                        </p>
                                        <p className="description">
                                            <b>Space Rangers HD: A War Apart</b> - обновленное переиздание Space Rangers, отличается рядом графических изменений и появлением двух новых ветвей развития за пирата или за агента под прикрытием.
                                        </p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/214730/Space_Rangers_HD_A_War_Apart/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Игра в STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D1%81%D0%BC%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D1%80%D0%B5%D0%B9%D0%BD%D0%B4%D0%B6%D0%B5%D1%80%D1%8B_2:_%D0%94%D0%BE%D0%BC%D0%B8%D0%BD%D0%B0%D1%82%D0%BE%D1%80%D1%8B"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <iframe width="" height="100%"
                                                        src="https://www.youtube.com/embed/HlixbA7_CH4"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupSpaceRangersReboot/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutSpaceRangersReboot;