import React, { Component } from "react";
import PortfolioGalleryPopupKingsBountyPrincess
    from "../../../elements/portfolio/PortfolioGalleryPopupKingsBountyPrincess";
import {FiChevronsRight} from "react-icons/all";


class AboutKingsBountyPrincess extends Component{
    render(){
        let title = 'King\'s Bounty: Принцесса в доспехах',
        description = 'Продолжение приключенческой тактической статегии "King\'s Bounty: Легенда о рыцаре", в которой игрок в роли принцессы Амели отправится в мир Теаны, где ей предстоит отыскать прославленного рыцаря и своего наставника Билла Гилберта. Героиня должна преодолеть все опасности неведомого мира и вернуться домой, чтобы отстоять родную столицу в сражении с огромной армией демонов, осадившей город.',
        description2 = 'Различные навыки и способности Амели, а также возможность свободного полёта позволят преодолеть любые трудности, кроме того, в пути героиню будет сопровождать настоящий ручной дракон. В битве он будет отважно защищать свою принцессу всеми доступными способами, начиная от простых ударов и заканчивая боевым безумием и пробуждением вулканов, а также поможет в поиске сокровищ.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_kings_bounty_princess.jpg" alt="King`s Bounty Princess Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2009</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Издатель:</div>
                                                <div>ValuSoft , 1C Entertainment</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Tactical role-playing game</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Рейтинги:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-metacritic.png"
                                                         alt="metacritic logo"/>
                                                    <span>Metascore 82 / 100</span>
                                                    <div>User Score 8.6 / 10</div>
                                                    <div>GameRankings 81%</div>
                                                </div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Награды:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>
                                                    <span>#28 Best PC Game of 2009</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>

                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/3170/Kings_Bounty_Armored_Princess"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Игра в STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/King%E2%80%99s_Bounty:_%D0%9F%D1%80%D0%B8%D0%BD%D1%86%D0%B5%D1%81%D1%81%D0%B0_%D0%B2_%D0%B4%D0%BE%D1%81%D0%BF%D0%B5%D1%85%D0%B0%D1%85"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <video controls
                                                       src="https://cdn.akamai.steamstatic.com/steam/apps/2031685/movie480.webm?t=1447361150"
                                                       poster="https://imgs.sector.sk/files/novinky/2009-8-27-14-53-89/kb%202009-08-27%2014-20-45-69.jpg"
                                                />
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupKingsBountyPrincess/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKingsBountyPrincess;