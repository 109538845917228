import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutGeneral extends Component{
    render(){
        let title = 'Генерал',
        description = '',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_general.jpg" alt="General"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2000</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Издатель:</div>
                                                <div>NewGame Software</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Turn-based strategy</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Рейтинги:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-metacritic.png"*/}
                                            {/*             alt="metacritic logo"/>*/}
                                            {/*        <span>Metascore 84 / 100,</span>*/}
                                            {/*        <div>User Score 8.9 / 10</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Награды:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>*/}
                                            {/*        <span>#46 Best PC Game of 2008</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*<p className="description">{description}</p>*/}
                                        {/*<p className="description">{description2}</p>*/}
                                        <div>
                                            <p className="description">
                                                "Генерал" это первая пошаговая стратегия придуманная и запрограммированная <a className="" href="https://ru.wikipedia.org/wiki/%D0%93%D1%83%D1%81%D0%B0%D1%80%D0%BE%D0%B2,_%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87" target="_blank" rel="noopener noreferrer">Дмитрием
                                                Гусаровым</a>, которая стала основой создания компании NewGame Software (Elemental Games).
                                                Игра представляет собой симулятор государства с экономической и стратегической составляющими. Игроку предстоит выращивать урожай и кормить крестьян, развивать науку и тренировать армию. Цель игрока — захватить остальные государства.
                                            </p>
                                            <p className="description">
                                                В игре присутствует поддержка многопользовательского режима через Интернет. Уникальная особенность игры состоит в том, что игроки могут совершать свои ходы одновременно. Можно вести баталии группами, объединяясь в союзы.
                                            </p>
                                            <p className="description">
                                                Игре более 20ти лет, но она прекрасно живет и проводит турниры на сайте <a href="http://akasoft.genliga.ru/index_r.php" target="_blank" rel="noopener noreferrer">Генеральской
                                                Лиги</a>. Добро пожаловать!
                                            </p>
                                        </div>
                                        <div className="purchase-btn mb--30 mt--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%93%D0%B5%D0%BD%D0%B5%D1%80%D0%B0%D0%BB_(%D0%B8%D0%B3%D1%80%D0%B0)"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="http://akasoft.genliga.ru/index_r.php"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Сайт игры Генерал <FiChevronsRight/></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutGeneral;