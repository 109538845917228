import React, {Component} from "react";
// import PortfolioGalleryPopupVangers from "../../../elements/portfolio/PortfolioGalleryPopupVangers";
import {FiChevronsRight} from "react-icons/all";

class AboutPerimeter extends Component {
    render() {
        let title = 'Периметр',
            description = '«Периметр» (в англоязычном издании — Perimeter) — компьютерная игра в жанре стратегии в реальном времени, созданная калининградской компанией К-Д ЛАБ в 2004 году. Особенность игры, делающая её уникальной в своём жанре — разрушаемое окружение. Игрок использует особенности рельефа для получения ресурсов для атаки и обороны. Кроме того, игрок часто вынужден противостоять геологическим процессам, разрушающим поверхность миров, и населяющим их враждебным созданиям — «скверне». Среди ключевых моментов игры также можно отметить возможность мгновенного превращения войск игрока из одного типа в другой прямо на поле боя и полноценную реализацию позиционных войн. Действие «Периметра» разворачивается в той же вымышленной вселенной, что и другого проекта К-Д ЛАБ — «Вангеры». События в «Периметре» предшествуют событиям, описанным в «Вангерах».',
            description2 = '';

        return (<React.Fragment>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-flex-start">
                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about_kranx_perimeter.jpg"
                                     alt="Perimeter Game"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    {/*<div className="icon">*/}
                                    {/*</div>*/}
                                    <h2 className="title">{title}</h2>
                                    <div className='description2'>
                                        <div className='item'>
                                            <div className='item-title'>Год:</div>
                                            <div>2003-2004</div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-title'>Платформа:</div>
                                            <div>PC</div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-title'>Жанр:</div>
                                            <div>real-time strategy, RTS</div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-title'>Награды:</div>
                                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                                <div>
                                                    <img width="40px" src="/assets/images/icons/kri_logo.jpg"
                                                         alt="logo"/>
                                                    <span>КРИ Awards: </span>
                                                    <div><i>"Лучшая технология" 2003</i></div>
                                                    <div><i>"Приз от прессы" 2003</i></div>
                                                    <div><i>"Лучшая игра для PC" 2004</i></div>
                                                    <div><i>"Лучший игровой дизайн" 2004</i></div>
                                                </div>
                                                <div>
                                                    <span>Навигатор Игрового мира: </span>
                                                    <div><i>"Game World Navigator Choice"</i></div>
                                                    <div><i>"Лучшая экспериментальная RTS 2004 года"</i></div>
                                                </div>
                                                <div>
                                                    <span>Игромания: </span>
                                                    <div><i>"Выбор редакции"</i></div>
                                                    <div><i>"Самая необычная игра 2004 года"</i></div>
                                                </div>
                                                <div>
                                                    <span>AG.ru: </span>
                                                    <div><i>"Наш выбор"</i></div>
                                                </div>
                                                <div>
                                                    <span>PC Gamer (Россия): </span>
                                                    <div><i>"PC GAMER рекомендует"</i></div>
                                                </div>
                                                <div>
                                                    <span>PC Gamer (UK): </span>
                                                    <div><i>"Игра месяца"</i></div>
                                                </div>
                                                <div>
                                                    <span>Страна игр: </span>
                                                    <div><i>"Страна Игр рекомендует"</i></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <p className="description">{description}</p>
                                    <div className="purchase-btn mb--30">
                                        <a href="https://ru.wikipedia.org/wiki/%D0%9F%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D1%82%D1%80_(%D0%B8%D0%B3%D1%80%D0%B0)"
                                           className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание
                                            в Wikipedia <FiChevronsRight/></a>
                                    </div>
                                    {/*<div className="videos">*/}
                                    {/*    <div className="video">*/}
                                    {/*        <iframe width="" height="100%"*/}
                                    {/*                src="https://www.youtube.com/embed/7smUD83nb_8"*/}
                                    {/*                title="YouTube video player" frameBorder="0"*/}
                                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                allowFullScreen></iframe>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="video">*/}
                                    {/*        <iframe width="560" height="315"*/}
                                    {/*                src="https://www.youtube.com/embed/Q85UNox8i0o"*/}
                                    {/*                title="YouTube video player" frameBorder="0"*/}
                                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                allowFullScreen></iframe>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="video">*/}
                                    {/*        <iframe width="560" height="315"*/}
                                    {/*                src="https://www.youtube.com/embed/P9R7QJ5Sh1o"*/}
                                    {/*                title="YouTube video player" frameBorder="0"*/}
                                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                allowFullScreen></iframe>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<PortfolioGalleryPopupVangers/>*/}
                                    <div className="mt--50">
                                        <h3 className="mb--30">Отзывы</h3>
                                        <div className="quotes">
                                            <figure className="quote">
                                                <blockquote>
                                                    ""Периметр" — знаковый продукт. Первая реальновременная стратегия за последние пару лет, в которую вообще имеет смысл играть"
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>PC Gamer (Россия)</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "Загадочная, но чрезвычайно привлекательная игра"
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>Страна Игр</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "Russian RTS stuns with beauty and innovation. We Want Inside this Perimeter". ("Русская RTS оглушает своей красотой и инновационностью. Мы хотим внутрь этого Периметра")"
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>www.pc.ign.com</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "Perimeter takes the real-time strategy genre, infuses it with a wealth of novel concepts, and wraps it all up in a great-looking graphical package". ("Периметр создан в жанре стратегий в реальном времени, и при этом он включает множество новинок, завёрнутых в прекрасно выглядящую графическую обёртку")"
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>www.gamerstemple.com</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "…a highly unusual game that aims to redefine the very „feel“ of real-time strategy". ("…в высшей степени необыкновенная игра, цель которой — переопределить само „чувство“ стратегий в реальном времени")"
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>GameSpy</cite></figcaption>
                                            </figure>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}

export default AboutPerimeter;