import React, {Component} from "react";
import PortfolioGalleryPopupKingsBountyCrossworlds
    from "../../../elements/portfolio/PortfolioGalleryPopupKingsBountyCrossworlds";
import {FiChevronsRight} from "react-icons/all";

class AboutKingsBountyCrossworlds extends Component {
    render() {
        let title = 'King\'s Bounty: Перекрестки миров',
            description = 'Масштабное самостоятельное дополнение к знаменитой ролевой игре "King\'s Bounty: Принцесса в доспехах". Содержит сюжетное дополнение "Поход орков", расширяющее и дополняющее сюжет оригинала, две независимые кампании — "Чемпион арены" и "Защитник короны" с редактором карт, новыми предметами, и новыми юнитами.',
            description2 = '';

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100"
                                         src="/assets/images/about/about_katauri_kings_bounty_crossworlds.jpg"
                                         alt="King`s Bounty Crossworlds Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2010</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Издатель:</div>
                                                <div>1C Entertainment</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Tactical role-playing game</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Рейтинги:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-metacritic.png"
                                                         alt="metacritic logo"/>
                                                    <span>Metascore 78 / 100</span>
                                                    <div>User Score 8.8 / 10</div>
                                                    <div>GameRankings 76%</div>
                                                </div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Награды:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>
                                                    <span>#54 Best PC Game of 2010</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/63910/Kings_Bounty_Crossworlds/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Игра в STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/King%E2%80%99s_Bounty:_%D0%9F%D0%B5%D1%80%D0%B5%D0%BA%D1%80%D1%91%D1%81%D1%82%D0%BA%D0%B8_%D0%BC%D0%B8%D1%80%D0%BE%D0%B2"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <video controls
                                                       src="https://cdn.cloudflare.steamstatic.com/steam/apps/256658581/movie480.webm?t=1448449896"
                                                       poster="/assets/images/preview/video_preview_katauri_kings_bounty_crossworlds.jpg"
                                                       />
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupKingsBountyCrossworlds/>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default AboutKingsBountyCrossworlds;