import React, { Component } from "react";
import PortfolioGalleryPopupSpaceRangers from "../../../elements/portfolio/PortfolioGalleryPopupSpaceRangers";
import {FiChevronsRight} from "react-icons/all";

class AboutSpaceRangers extends Component{
    render(){
        let title = 'Космические Рейнджеры',
        description = 'Ролевая приключенческая игра с элементами тактики. Космический и торговый симулятор. Выделяется наличием живого мира и настоящим искусственным интеллектом пилотов, где каждый воин, торговец, пират или рейнджер проживает свою собственную жизнь, вне зависимости от игрока!',
        description2 = 'Игра повествует о противоборстве между Галактическим содружеством, состоящим из пяти рас и вторгшимися силами клисан — неизвестной, агрессивно настроенной формой жизни. Игроку предстоит выступить в роли рейнджера-добровольца, участника организации, созданной специально для борьбы с клисанами.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_space_rangers.jpg" alt="Space Ranger Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2002</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Издатель:</div>
                                                <div>1C Entertainment, Micro Application</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Role Playing Game, Space trading and combat simulator</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Рейтинги:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-metacritic.png"
                                                         alt="metacritic logo"/>
                                                    <span>User Score 8.8 / 10</span>
                                                </div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Награды:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>*/}
                                            {/*        <span>#46 Best PC Game of 2008</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D1%81%D0%BC%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D1%80%D0%B5%D0%B9%D0%BD%D0%B4%D0%B6%D0%B5%D1%80%D1%8B"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Описание в Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        {/*<div className="videos mb--30">*/}
                                        {/*    <div className="video">*/}
                                        {/*        <iframe width="" height="100%"*/}
                                        {/*                src=""*/}
                                        {/*                title="YouTube video player" frameBorder="0"*/}
                                        {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                        {/*                allowFullScreen></iframe>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <PortfolioGalleryPopupSpaceRangers/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutSpaceRangers;